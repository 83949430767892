import React from 'react';
import styled from 'styled-components';
import { device } from '../../../theme';

export const ProjectSectionWrap = styled.section`
  background-color: ${(props) => props.backgroundColor};
  padding: 100px 12.5%;
  @media ${device.medium} {
    padding: 70px 0;
  }
  @media ${device.small} {
    padding: 10vw 0;
  }
  .project-wrapper {

    padding: 30px 0;

    }

    &:hover {
      .summary-wrap {
        opacity: 1;
      }
      .project-details {
        h3 {
          color: #afafaf;
          margin-top: 0;
        }
        p {
          color: #efefefff;
          margin-top: 0;
          @media ${device.small} {
            /* margin-top: -35px; */
          }
        }
      }
    }
  }
`;

export const SummaryContent = styled.div`
  display: flex;
  flex: column nowrap;
  padding: 25px 25px;
  width: 100%;
  justify-content: flex-start;
  align-content: flex-start;
  transition: all 0.3s ease-in-out;
  background-color: #222;
  opacity: 0;
  @media ${device.small} {
    padding: 25px 5px;
  }
`;

const Item = styled.div`
  padding: 5px 10px;
  width: 25%;

  h3 {
    display: block;
    font-size: 1.2em;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #777;
    margin-bottom: 5px;
    transition: all 0.3s;
    margin-top: -30px;
  }
  p {
    display: block;
    font-size: 1em;
    color: #efefef00;
    transition: all 0.3s;
    margin: 0;
    margin-top: -30px;
  }

  @media ${device.small} {
    width: 35%;
    padding: 5px 10px;

    h3 {
      font-size: .6em;
      padding-left: 15px;
    }
    p {
      font-size: .8em;
      padding-left: 15px;
      line-height: 1.5;
    }
  }
`;

export const SummaryItem = ({ title, value }) => {
  return (
    <Item className="project-details">
      <h3>{title}</h3>
      <p>{value}</p>
    </Item>
  );
};
