import React from 'react';
import SEO from '../components/seo';
import Layout from '../containers/layout/layout';
import Hero from '../components/hero';
import Body from '../components/body';
import Project from '../containers/projects/project'
import { graphql } from 'gatsby'

export const projectsPageData = graphql`
  query projectsPageQuery {
    sanityProjects {
      hero {
        title
        subtitle
        image {
          alt
          asset {
            fluid(maxWidth: 1920, maxHeight: 750) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      headline
      subhead
      accent {
        asset {
          fluid(maxWidth: 1920, maxHeight: 750) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawCopy
      openGraph {
        title
        description
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

const ProjectsPage = (props) => {
  const { data } = props;

  const pageData = data.sanityProjects;
  const seoMeta = pageData.seo;
  const openGraph = pageData.openGraph;

  return (
    <Layout headerLayout={2}>
    <SEO
      title={seoMeta?.seo_title}
      ogtitle={openGraph?.title}
      description={seoMeta?.meta_description}
      ogdescription={openGraph?.description}
      image={openGraph?.image?.asset?.fluid}
      pathname={props.location.pathname}
    />
      <Hero
        title={pageData.hero.title}
        subhead={pageData.hero.subtitle}
        image={pageData.hero.image.asset.fluid}
        alt={pageData.hero.image.alt}
      />
      <Body
        title={pageData.headline}
        subhead={pageData.subhead}
        copy={pageData._rawCopy}
        image={pageData.accent.asset.fluid}
        titleAlign={'left'}
      />
      <Project />
    </Layout>
  );
};

export default ProjectsPage