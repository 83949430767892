import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Project from '../../../components/project';
import { ProjectSectionWrap, SummaryContent, SummaryItem } from './project.stc';

const ProjectSection = ({ section, project }) => {
  const projectQueryData = useStaticQuery(graphql`
    query ProjectPageDataQuery {
      sanityHome {
        _rawProjects
      }
      allSanityProject {
        edges {
          node {
            _id
            slug {
              current
            }
            title
            client
            category
            isFeatured
            _rawImg
            _rawHomeSummary
          }
        }
      }
    }
  `);

  const projectsData = projectQueryData.allSanityProject.edges;

  return (
    <ProjectSectionWrap {...section}>
      <div className="project-container">
        {projectsData.map((data, i) => {
          let isEven = i % 2 === 0;
          return (
            <div className="project-wrapper" key={data.node.slug.current}>
              <Project
                {...project}
                isEven={isEven}
                slug={data.node.slug.current}
                image={data.node._rawImg}
                title={data.node.title}
                client={data.node.client}
                category={data.node.category}
                summary={data.node._rawHomeSummary}
              />
              <SummaryContent className="summary-wrap">
                <SummaryItem title={'Project'} value={data.node.title} />
                <SummaryItem title={'Client'} value={data.node.client} />
                <SummaryItem title={'Category'} value={data.node.category} />
              </SummaryContent>
            </div>
          );
        })}
      </div>
    </ProjectSectionWrap>
  );
};

ProjectSection.propTypes = {
  section: PropTypes.object,
  project: PropTypes.object,
};

ProjectSection.defaultProps = {
  section: {
    backgroundColor: '#f8f8f8',
  },
  project: {
    mt: '100px',
    responsive: {
      medium: {
        mt: '50px',
      },
    },
  },
};

export default ProjectSection;
